import React, { useEffect } from 'react'
import { useUniqueId } from '@te-digi/styleguide'

import { LinkMock, LinkMockTargetBlank } from '../../components/LinkMock'

import { HeaderSearchMobileHTMLExample } from './HeaderSearchHTMLExample'
import IconHTMLExample from './IconHTMLExample'
import LogoHTMLExample from './LogoHTMLExample'
import LanguageMenu from './LanguageMenu'

export const HeaderMobileNavButton = ({ id }: { id: string }) => (
  <button
    aria-label="Päävalikko"
    aria-controls={id}
    aria-expanded="false"
    className="btn button-mobile-menu-opener header-toggler"
    data-toggle="show"
    type="button"
  >
    <span className="mobile-menu-opener-content">
      <span className="mobile-menu-opener-icon">
        <IconHTMLExample
          className="header-toggler-icon-x"
          name="XIcon"
          size="lg"
        />
        <IconHTMLExample
          className="header-toggler-icon-menu"
          name="MenuIcon"
          size="lg"
        />
      </span>
      <span className="mobile-menu-opener-label">
        <span className="header-toggler-label-x">Sulje valikko</span>
        <span className="header-toggler-label-menu">Valikko</span>
      </span>
    </span>
  </button>
)

const HeaderAsiointiMobileHTMLExample = ({ id }: { id: string }) => {
  const mobileNavId = useUniqueId('mobile-nav')

  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
  }, [])

  return (
    <>
      <div className="header-area-nav-container">
        <div className="header-logo-container">
          <LinkMock>
            <LogoHTMLExample />
          </LinkMock>
        </div>
        <HeaderMobileNavButton id={mobileNavId} />
      </div>

      <div
        className="mobile-nav"
        id={mobileNavId}
      >
        <div className="mobile-nav-search">
          {HeaderSearchMobileHTMLExample()}
        </div>

        <div className="mobile-nav-tools">
          <LinkMockTargetBlank className="mobile-instructions-link">
            Ohjeet ja tuki
            <IconHTMLExample
              name="ExternalLinkIcon"
              className="header-toolbar-instructions-icon"
              size="lg"
              ariaLabel="Avautuu uuteen välilehteen"
            />
          </LinkMockTargetBlank>

          <div className="mobile-nav-language-select">
            <LanguageMenu
              handle="language-menu-mobile"
              buttonClass="btn-plain-primary"
            />
          </div>
        </div>

        <nav>
          <ul
            className="mobile-menu-list"
            id="mobile-nav-33"
          >
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item active">
                <LinkMock className="mobile-menu-link mobile-menu-link-with-button">
                  <IconHTMLExample
                    name="UserIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Henkilöasiakkaat
                </LinkMock>
                <button
                  aria-label="Henkilöasiakkaat: Näytä tai piilota alasivut"
                  aria-controls="mobile-nav-44"
                  aria-expanded="false"
                  className="btn btn-plain-primary mobile-menu-toggler mobile-menu-button"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="btn-content">
                    <IconHTMLExample
                      className="btn-icon mobile-menu-toggler-icon-expand"
                      name="ExpandIcon"
                      size="lg"
                    />
                    <IconHTMLExample
                      className="btn-icon mobile-menu-toggler-icon-collapse"
                      name="CollapseIcon"
                      size="lg"
                    />
                  </span>
                </button>
              </span>
              <ul
                className="mobile-menu-list collapse"
                id="mobile-nav-44"
              >
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      Avoimet työpaikat
                    </LinkMock>
                  </span>
                </li>
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      Koulutukset ja palvelut
                    </LinkMock>
                  </span>
                </li>
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link mobile-menu-link-with-button">
                      Tietoa eri tilanteisiin
                    </LinkMock>
                    <button
                      aria-label="Tietoa eri tilanteisiin: Näytä tai piilota alasivut"
                      aria-controls="mobile-nav-64"
                      aria-expanded="false"
                      className="btn btn-plain-primary mobile-menu-toggler mobile-menu-button"
                      data-toggle="collapse"
                      type="button"
                    >
                      <span className="btn-content">
                        <IconHTMLExample
                          className="btn-icon mobile-menu-toggler-icon-expand"
                          name="ExpandIcon"
                          size="lg"
                        />
                        <IconHTMLExample
                          className="btn-icon mobile-menu-toggler-icon-collapse"
                          name="CollapseIcon"
                          size="lg"
                        />
                      </span>
                    </button>
                  </span>
                  <ul
                    className="mobile-menu-list collapse"
                    id="mobile-nav-64"
                  >
                    <li className="mobile-menu-list-item">
                      <span className="mobile-menu-item">
                        <LinkMock className="mobile-menu-link">
                          Koulutus ja opiskelu
                        </LinkMock>
                      </span>
                    </li>
                    <li className="mobile-menu-list-item">
                      <span className="mobile-menu-item">
                        <LinkMock className="mobile-menu-link">
                          Työnhaku
                        </LinkMock>
                        <button
                          aria-label="Työnhaku: Näytä tai piilota alasivut"
                          aria-controls="mobile-nav-74"
                          aria-expanded="false"
                          className="btn btn-plain-primary mobile-menu-toggler mobile-menu-button"
                          data-toggle="collapse"
                          type="button"
                        >
                          <span className="btn-content">
                            <IconHTMLExample
                              className="btn-icon mobile-menu-toggler-icon-expand"
                              name="ExpandIcon"
                              size="lg"
                            />
                            <IconHTMLExample
                              className="btn-icon mobile-menu-toggler-icon-collapse"
                              name="CollapseIcon"
                              size="lg"
                            />
                          </span>
                        </button>
                      </span>
                      <ul
                        className="mobile-menu-list collapse"
                        id="mobile-nav-74"
                      >
                        <li className="mobile-menu-list-item">
                          <span className="mobile-menu-item active">
                            <LinkMock className="mobile-menu-link">
                              Asiointi ja ilmoittautuminen työnhakijaksi
                            </LinkMock>

                            <button
                              aria-label="Asiointi ja ilmoittautuminen työnhakijaksi: Näytä tai piilota alasivut"
                              aria-controls="mobile-nav-84"
                              aria-expanded="false"
                              className="btn btn-plain-primary mobile-menu-toggler mobile-menu-button"
                              data-toggle="collapse"
                              type="button"
                            >
                              <span className="btn-content">
                                <IconHTMLExample
                                  className="btn-icon mobile-menu-toggler-icon-expand"
                                  name="ExpandIcon"
                                  size="lg"
                                />
                                <IconHTMLExample
                                  className="btn-icon mobile-menu-toggler-icon-collapse"
                                  name="CollapseIcon"
                                  size="lg"
                                />
                              </span>
                            </button>
                          </span>
                          <ul
                            className="mobile-menu-list collapse"
                            id="mobile-nav-84"
                          >
                            <li className="mobile-menu-list-item">
                              <span className="mobile-menu-item active">
                                <LinkMock className="mobile-menu-link">
                                  Ilmoittautumisohjeet Suomen kansalaiselle
                                </LinkMock>
                              </span>
                            </li>
                            <li className="mobile-menu-list-item">
                              <span className="mobile-menu-item">
                                <LinkMock className="mobile-menu-link">
                                  Ilmoittautumisohjeet EU- ja ETA-maan ja
                                  Sveitsin kansalaiselle
                                </LinkMock>
                              </span>
                            </li>
                            <li className="mobile-menu-list-item">
                              <span className="mobile-menu-item">
                                <LinkMock className="mobile-menu-link">
                                  Ukrainalaisten työnhakijaksi ilmoittautuminen
                                  ja työnhaku Suomessa
                                </LinkMock>
                              </span>
                            </li>
                            <li className="mobile-menu-list-item">
                              <span className="mobile-menu-item">
                                <LinkMock className="mobile-menu-link">
                                  Ilmoittautumisohjeet muun maan kansalaiselle
                                </LinkMock>
                              </span>
                            </li>
                          </ul>
                        </li>
                        <li className="mobile-menu-list-item">
                          <span className="mobile-menu-item">
                            <LinkMock className="mobile-menu-link">
                              Vinkkejä työnhakuun
                            </LinkMock>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="mobile-menu-list-item">
                      <span className="mobile-menu-item">
                        <LinkMock className="mobile-menu-link">
                          Palveluita työnhaun tueksi
                        </LinkMock>
                      </span>
                    </li>
                    <li className="mobile-menu-list-item">
                      <span className="mobile-menu-item">
                        <LinkMock className="mobile-menu-link">
                          Työnhakijan asiakaspalvelumalli
                        </LinkMock>
                      </span>
                    </li>
                    <li className="mobile-menu-list-item">
                      <span className="mobile-menu-item">
                        <LinkMock className="mobile-menu-link">
                          Työnhankijan palvelut työllisyyden kuntakokeilun
                          aikana
                        </LinkMock>
                      </span>
                    </li>
                    <li className="mobile-menu-list-item">
                      <span className="mobile-menu-item">
                        <LinkMock className="mobile-menu-link">
                          Nuorisotakuu
                        </LinkMock>
                      </span>
                    </li>
                  </ul>
                </li>
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      <IconHTMLExample
                        className="page-header-item-icon"
                        name="SpeechBubbleFillIcon"
                      />
                      Oma työpolku
                    </LinkMock>
                  </span>
                </li>
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      <IconHTMLExample
                        className="page-header-item-icon icon-brand"
                        name="TePalvelutFillIcon"
                      />
                      Asiointi
                    </LinkMock>
                  </span>
                </li>
              </ul>
            </li>
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item">
                <LinkMock className="mobile-menu-link mobile-menu-link-with-button">
                  <IconHTMLExample
                    name="GroupIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Työnantajat ja yrittäjät
                </LinkMock>
                <button
                  aria-label="Työnantajat ja yrittäjät: Näytä tai piilota alasivut"
                  aria-controls="mobile-nav-54"
                  aria-expanded="false"
                  className="btn btn-plain-primary mobile-menu-toggler mobile-menu-button"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="btn-content">
                    <IconHTMLExample
                      className="btn-icon mobile-menu-toggler-icon-expand"
                      name="ExpandIcon"
                      size="lg"
                    />
                    <IconHTMLExample
                      className="btn-icon mobile-menu-toggler-icon-collapse"
                      name="CollapseIcon"
                      size="lg"
                    />
                  </span>
                </button>
              </span>

              <ul
                className="mobile-menu-list collapse"
                id="mobile-nav-54"
              >
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      Koulutukset ja palvelut
                    </LinkMock>
                  </span>
                </li>
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item active">
                    <LinkMock className="mobile-menu-link">
                      Tietoa työelämästä
                    </LinkMock>
                  </span>
                </li>
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      Yrityksen asiointi
                    </LinkMock>
                  </span>
                </li>
              </ul>
            </li>
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item">
                <LinkMock className="mobile-menu-link">
                  <IconHTMLExample
                    name="LocationIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Aluesivut
                </LinkMock>
              </span>
            </li>
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item">
                <LinkMock className="mobile-menu-link">
                  <IconHTMLExample
                    name="AlertCircleIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Ammatit ja työllisyys
                </LinkMock>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export { HeaderAsiointiMobileHTMLExample }
