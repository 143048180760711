import React from 'react'

import { LinkMock } from '../../components/LinkMock'

import IconHTMLExample from './IconHTMLExample'

type Props = {
  buttonClass?: string
  handle?: string
}

const LanguageMenu = ({
  handle = 'language-menu',
  buttonClass = ''
}: Props) => (
  <div className="dropdown language-menu-dropdown">
    <button
      aria-controls={handle}
      aria-expanded="false"
      aria-haspopup="true"
      className={'btn ' + buttonClass}
      data-toggle="dropdown"
      id={handle + '-button'}
      type="button"
    >
      <span className="btn-content">
        <span className="btn-label">Suomi (FI)</span>
        <IconHTMLExample
          className="btn-icon-right"
          name="ChevronDownIcon"
          size="lg"
        />
      </span>
    </button>
    <div
      aria-labelledby={handle + '-button'}
      className="dropdown-menu dropdown-menu-right language-menu-container"
      id={handle}
    >
      <p
        className="lead ml-lg mt-md mb-0"
        id="language-change-label"
      >
        Vaihda kieltä
      </p>
      <ul
        aria-labelledby="language-change-label"
        className="dropdown-actions"
      >
        <li>
          <LinkMock
            className="dropdown-actions-item"
            aria-current="true"
          >
            <span className="selected-language-icon-placeholder">
              <IconHTMLExample
                name="CheckIcon"
                ariaLabel="valittu"
                size="lg"
              />
            </span>
            <span className="dropdown-actions-item-label">Suomi</span>
          </LinkMock>
        </li>
        <li>
          <LinkMock className="dropdown-actions-item">
            <span className="selected-language-icon-placeholder" />
            <span className="dropdown-actions-item-label">Svenska</span>
          </LinkMock>
        </li>
        <li>
          <LinkMock className="dropdown-actions-item">
            <span className="selected-language-icon-placeholder" />
            <span className="dropdown-actions-item-label">English</span>
          </LinkMock>
        </li>
      </ul>
      <div className="dropdown-divider header-toolbar-desktop-tool" />

      <p className="lead ml-lg mt-md mb-0">Tietoa muilla kielillä</p>
      <ul
        aria-labelledby="language-change-label"
        className="dropdown-actions"
      >
        <li>
          <LinkMock className="dropdown-actions-item justify-content-between">
            <span className="dropdown-actions-item-label">Selkosuomi</span>
            <IconHTMLExample
              name="ArrowRightIcon"
              size="lg"
            />
          </LinkMock>
        </li>
        <li>
          <LinkMock className="dropdown-actions-item justify-content-between">
            <span className="dropdown-actions-item-label">Saame1</span>
            <IconHTMLExample
              name="ArrowRightIcon"
              size="lg"
            />
          </LinkMock>
        </li>
        <li>
          <LinkMock className="dropdown-actions-item justify-content-between">
            <span className="dropdown-actions-item-label">Saame2</span>
            <IconHTMLExample
              name="ArrowRightIcon"
              size="lg"
            />
          </LinkMock>
        </li>
        <li>
          <LinkMock className="dropdown-actions-item justify-content-between">
            <span className="dropdown-actions-item-label">Saame3</span>
            <IconHTMLExample
              name="ArrowRightIcon"
              size="lg"
            />
          </LinkMock>
        </li>
      </ul>
    </div>
  </div>
)

export default LanguageMenu
