import React, { useState } from 'react'
import {
  Button,
  Dropdown,
  DropdownSection,
  HeaderATMT,
  HeaderBackground,
  Inline,
  Lead,
  LockIcon,
  Paragraph,
  QuestionCircleIcon,
  TyomarkkinatoriAsiantuntijaSmallFiWhiteSvg,
  useBreakpoint,
  UserIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

import { PlainSelectExample1 } from './PlainSelectExample1'

interface HeaderATMTExampleProps {
  renderMenu?: boolean
}

/*
 * Note! The prop "renderMenu" is created so that this example can be used in
 * Style Guide documentation page. That page can't utilize any examples
 * containing menu prop. This is because of the bug in
 * react-element-to-jsx-string 14.3.3 or higher
 * (see https://github.com/algolia/react-element-to-jsx-string/issues/667)
 *
 * We can't use version 14.3.2 because it does not support React 18, and it will
 * lead to problems in dev mode
 * (see https://reactjs.org/warnings/invalid-hook-call-warning.html)
 *
 * So we are using the latest version of the library and try to handle with the
 * bug.
 */

const HeaderATMTExample = ({ renderMenu = true }: HeaderATMTExampleProps) => {
  const isLg = useBreakpoint('lg')

  const [activeItem, setActiveItem] = useState('Henkilöasiakkaat')
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [hasUser, setHasUser] = useState(false)

  const toggleLogin = () => setHasUser(!hasUser)

  const hasUserContent = (
    <Dropdown
      placement="bottom-end"
      trigger={
        <Button
          color={'white'}
          iconLeft={<UserIcon />}
          variant={'plain'}
        >
          <Inline>Veli Viranomainen</Inline>
        </Button>
      }
    >
      <DropdownSection>
        <Lead noMargin>Veli Viranomainen</Lead>
        <Paragraph>Uudenmaan työ- ja elinkeinotoimisto</Paragraph>
        <Button
          variant={'plain'}
          iconLeft={<LockIcon />}
          onClick={toggleLogin}
        >
          Kirjaudu ulos
        </Button>
      </DropdownSection>
    </Dropdown>
  )

  const noUserContent = (
    <Button
      color={'white'}
      iconLeft={<UserIcon />}
      onClick={toggleLogin}
      variant={'plain'}
    >
      Kirjaudu sisään
    </Button>
  )

  const instructionsComponent = (
    <Button
      as={LinkMock}
      color={isLg ? 'white' : 'primary'}
      iconLeft={<QuestionCircleIcon />}
      variant={'plain'}
    >
      Ohjeet ja tuki
    </Button>
  )

  return (
    <HeaderBackground>
      <HeaderATMT
        isNavOpen={isNavOpen}
        languageSelect={PlainSelectExample1()}
        logo={
          <LinkMock>
            <TyomarkkinatoriAsiantuntijaSmallFiWhiteSvg ariaLabel="Siirry etusivulle" />
          </LinkMock>
        }
        instructions={instructionsComponent}
        menu={
          renderMenu
            ? [
                {
                  component: (
                    <LinkMock
                      to={'#tyotehtavat'}
                      onClick={() => setActiveItem('Työtehtävät')}
                    />
                  ),
                  label: 'Työtehtävät',
                  active: activeItem === 'Työtehtävät'
                },
                {
                  component: (
                    <LinkMock
                      to={'#henkiloasiakkaat'}
                      onClick={() => setActiveItem('Henkilöasiakkaat')}
                    />
                  ),
                  label: 'Henkilöasiakkaat',
                  active: activeItem === 'Henkilöasiakkaat'
                },
                {
                  component: (
                    <LinkMock
                      to={'#tyonsntajatjayrittajat'}
                      onClick={() => setActiveItem('Työnantajat ja yrittäjät')}
                    />
                  ),
                  label: 'Työnantajat ja yrittäjät',
                  active: activeItem === 'Työnantajat ja yrittäjät'
                },
                {
                  component: (
                    <LinkMock
                      to={'#palvelut'}
                      onClick={() => setActiveItem('Palvelut')}
                    />
                  ),
                  label: 'Palvelut',
                  active: activeItem === 'Palvelut'
                }
              ]
            : []
        }
        onNavToggle={() => setIsNavOpen(!isNavOpen)}
        user={hasUser ? hasUserContent : noUserContent}
      />
    </HeaderBackground>
  )
}

export { HeaderATMTExample }
