import React from 'react'
import { useUniqueId } from '@te-digi/styleguide'

import { LinkMock, LinkMockTargetBlank } from '../../components/LinkMock'

import { HeaderSearchAsiointiHTMLExample } from './HeaderSearchHTMLExample'
import IconHTMLExample from './IconHTMLExample'
import LogoKayttoohjeetHTMLExample from './LogoKayttoohjeetHTMLExample'

import { HeaderKayttoohjeetMobileHTMLExample } from './HeaderKayttoohjeetMobileHTMLExample'
import LanguageMenu from './LanguageMenu'

const HeaderKayttoohjeetHTMLExample = () => {
  const languageMenuId = useUniqueId('language-menu')

  return (
    <header className="header-background header-content">
      <a
        className="btn btn-outline-primary skip-link"
        href="#content"
      >
        <span className="btn-content">
          <span className="btn-label">Hyppää sisältöön</span>
        </span>
      </a>

      <div className="header-toolbar-instructions">
        <div className="header-logo-container logo-instructions">
          <LinkMock>
            <LogoKayttoohjeetHTMLExample />
          </LinkMock>
        </div>

        <div className="header-toolbar">
          <button
            className="btn btn-primary btn-plain-white header-toolbar-button header-toolbar-desktop-tool"
            aria-controls="search-bar-container"
            aria-expanded="false"
            data-toggle="collapse"
            id="header-search-button"
            type="button"
          >
            <span className="btn-content">
              <IconHTMLExample
                name="SearchIcon"
                className="btn-icon-left"
                size="lg"
              />
              <span className="btn-label">Haku</span>
            </span>
          </button>

          <div className="header-divider header-toolbar-desktop-tool" />

          <LinkMockTargetBlank className="header-toolbar-desktop-tool toolbar-instructions-link">
            Anna palautetta
            <IconHTMLExample
              name="ExternalLinkIcon"
              className="header-toolbar-instructions-icon"
              size="lg"
              ariaLabel="Avautuu uuteen välilehteen"
            />
          </LinkMockTargetBlank>

          <div className="header-divider header-toolbar-desktop-tool" />

          <div className="header-toolbar-desktop-tool language-instructions">
            <LanguageMenu
              buttonClass="btn-primary btn-square header-toolbar-button"
              handle={languageMenuId}
            />
          </div>
        </div>
      </div>
      <div className="header-mobile">
        <HeaderKayttoohjeetMobileHTMLExample />
      </div>
      <div className="header-desktop">
        <div
          className="search-bar-container collapse"
          id="search-bar-container"
        >
          <HeaderSearchAsiointiHTMLExample />
        </div>
        <div className="header-area-nav-container header-area-nav-container-instructions">
          <nav
            aria-label="Päänavigaatio"
            className="area-nav"
          >
            <ul className="area-nav-list">
              <li className="area-nav-item">
                <LinkMock
                  className="area-nav-link active"
                  aria-current="true"
                  data-content="Henkilöasiakkaat"
                  id="main-nav-henkiloasiakkaat"
                >
                  <IconHTMLExample
                    name="UserIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Henkilöasiakkaat
                </LinkMock>
              </li>
              <li className="area-nav-item">
                <LinkMock
                  className="area-nav-link"
                  data-content="Työnantajat ja yrittäjät"
                  id="area-nav-tyonantajat-ja-yrittajat"
                >
                  <IconHTMLExample
                    name="GroupIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Työnantajat ja yrittäjät
                </LinkMock>
              </li>
              <li className="area-nav-item area-nav-item-with-divider">
                <LinkMock
                  className="area-nav-link"
                  data-content="Usein kysytyt kysymykset"
                  id="area-nav-ukk"
                >
                  <IconHTMLExample
                    name="QuestionIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Usein kysytyt kysymykset
                </LinkMock>
              </li>
              <li className="area-nav-item">
                <LinkMock
                  className="area-nav-link"
                  data-content="Asiakaspalvelu"
                  id="area-nav-asiakaspalvelu"
                >
                  <IconHTMLExample
                    name="CustomerServiceIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Asiakaspalvelu
                </LinkMock>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header-main-nav-container">
          <div className="third-level-navigation">
            <nav
              className="secondary-nav"
              aria-labelledby="main-nav-henkiloasiakkaat"
            >
              <ul className="secondary-nav-list">
                <li className="secondary-nav-item">
                  <a
                    className="secondary-nav-link active"
                    data-content="Työn etsiminen ja osaamisen kehittäminen"
                    href="/"
                  >
                    Työn etsiminen ja osaamisen kehittäminen
                  </a>
                </li>
                <li className="secondary-nav-item">
                  <a
                    className="secondary-nav-link"
                    data-content="Asiointi työvoimaviranomaisen kanssa"
                    href="/"
                  >
                    Asiointi työvoimaviranomaisen kanssa
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export { HeaderKayttoohjeetHTMLExample }
