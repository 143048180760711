import React from 'react'

import kayttoohjeetSvg from '@te-digi/styleguide/assets/ohjeet-ja-tuki-tmt-fi.svg'

const LogoKayttoohjeetHTMLExample = ({
  alt = 'Käyttöohjeet etusivu',
  className = 'logo-header',
  src = kayttoohjeetSvg
}: {
  alt?: string
  className?: string
  src?: string
}) => (
  <img
    alt={alt}
    className={className}
    src={src}
  />
)

export default LogoKayttoohjeetHTMLExample
