import React from 'react'

import {
  Header,
  HeaderATMT,
  HeaderBackground,
  HeaderButton,
  HeaderSearch,
  Help,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { HeaderAsiointiHTMLExample } from '../../examples/html/HeaderAsiointiHTMLExample'
import { HeaderKayttoohjeetHTMLExample } from '../../examples/html/HeaderKayttoohjeetHTMLExample'
import { HeaderHTMLExample } from '../../examples/html/HeaderHTMLExample'
import { HeaderUserHTMLExample } from '../../examples/html/HeaderUserHTMLExample'
import { PlainSelectHTMLExample } from '../lomakkeet/plainselect'
import { Section } from '../../components/Section'
import { HeaderATMTExample } from '../../examples/react/HeaderATMTExample'
import { HeaderATMTWithManyOrganizationsExample } from '../../examples/react/HeaderATMTWithManyOrganizationsExample'
import { HeaderTMTExample } from '../../examples/react/HeaderTMTExample'

export const HeaderMinimalHTMLExample = () => (
  <div className="page-header-background">
    <header className="page-header page-header-minimal">
      <div className="page-header-language">{PlainSelectHTMLExample()}</div>
    </header>
  </div>
)

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Header"
    components={[
      { component: Header },
      { component: HeaderATMT },
      { component: HeaderBackground },
      { component: HeaderButton },
      { component: HeaderSearch }
    ]}
  >
    <Section>
      <Help variant="error">
        <Paragraph noMargin>
          <strong>HUOM!</strong> Kolmannen osapuolen kirjaston bugin vuoksi
          React esimerkeistä joissa on <Code>menu</Code> mukana on poistettu
          mahdollisuus tarkastella koodia. Ole yhteydessä Style Guideen jos
          tarvitset esimerkin <Code>menu</Code>n rakenteesta.
        </Paragraph>
      </Help>
    </Section>

    <Section title={'TMT header'}>
      <Paragraph>
        Luokka <Code>header-asiointi</Code> vain Asioinnin sivuille.
      </Paragraph>
      <Playground
        enableOverflow
        example={HeaderAsiointiHTMLExample}
        format="html"
        widePreview
      />
    </Section>
    <Section title={'TMT Käyttöohjeet'}>
      <Playground
        enableOverflow
        example={HeaderKayttoohjeetHTMLExample}
        format="html"
        widePreview
      />
    </Section>

    <Section title={'Vanha TMT header (esimerkit poistuvat)'}>
      <Playground
        enableOverflow
        example={HeaderHTMLExample}
        format="html"
        widePreview
      />
      <Playground
        enableOverflow
        example={HeaderUserHTMLExample}
        format="html"
        widePreview
      />

      <Playground
        enableOverflow
        widePreview
      >
        {HeaderTMTExample({ renderMenu: false })}
      </Playground>
      <Playground
        enableOverflow
        example={HeaderTMTExample}
        hideCode
        widePreview
      />
    </Section>
    <Section title={'A-TMT header'}>
      <Paragraph>
        Käyttäjälle, jolla on vain yksi organisaatio, organisaatiotietoa ei
        näytetä painikkeessa, vaan avautuvassa valikossa.
      </Paragraph>

      <Playground
        enableOverflow
        widePreview
      >
        {HeaderATMTExample({ renderMenu: false })}
      </Playground>

      <Paragraph>
        Käyttäjälle, jolla on useampi organisaatio, on olennaista näyttää
        valittuna oleva organisaatio painikkeessa. Organisaation voi vaihtaa
        valikon kautta. Valintaan käytetään Organisaatiovalinta-modaalia.
      </Paragraph>

      <Playground
        enableOverflow
        widePreview
      >
        {HeaderATMTWithManyOrganizationsExample({ renderMenu: false })}
      </Playground>

      <Paragraph>
        Esimerkki Headerista navigaatiolla. Koodiesimerkin saat pyytämällä Style
        Guide -tiimiltä.
      </Paragraph>

      <Playground
        enableOverflow
        example={HeaderATMTExample}
        hideCode
        widePreview
      />
    </Section>

    <Section title="Saavutettavuus (Verkkopalvelu)">
      <Paragraph>
        1-tason <Code>nav</Code>-elementille annetaan nimi{' '}
        <Code>aria-label</Code>illa (&quot;Päänavigaatio&quot;). 2-tason
        navigaatio linkitetään ylätason valittuun elementtiin:{' '}
        <Code>
          &lt;nav class=&quot;secondary-nav&quot;
          aria-labelledby=&quot;level-1-nav-item-x&quot;&gt;
        </Code>
      </Paragraph>
      <Paragraph>
        Jos vain 1-tason navigaatiosta on valittu sivu, sille merkitään{' '}
        <Code>aria-current=page</Code>. Jos myös 2-tason navigaatiosta on
        valittu sivu, 1-tason navigaation sivu merkitään{' '}
        <Code>aria-current=true</Code> ja 2-tason valittu sivu merkitään{' '}
        <Code>aria-current=page</Code>.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
